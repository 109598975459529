<template>
    <div>                                
        <div class="form-row">	
            <div class="col input-group my-3 px-3">
                <div class="form-floating">
                    <input type="text" v-on:input="showSearch()" id="allocationSearch" class="form-control" v-model="search" placeholder="Search by fund name">
                    <label for="allocationSearch">Search by fund name</label>                
                </div>
                <button type="input-group-text button" :disabled="search == ''" v-on:click="search = ''; showSearch()" class="input-group-text btn btn-danger"><i class="fas fa-times"></i> Clear</button>                
            </div>			
        </div>
        <div class="collapse" id="allocationCollapse">
            <ul v-if="paginatedData.length" class="ms-2 me-2 list-group list-group-flush">
                <li class="list-group-item" :key="i" v-for="(a, i) in paginatedData">                                                                 
                    <!--<span v-html="$root.highlight(a.NCSU_LONG_NAME, search.toLowerCase())"></span> <i v-if="a.NOTE_TEXT > ''" class="has-tooltip text-primary fas fa-lg fa-info-circle" v-tooltip:bottom="a.NOTE_TEXT"></i>-->
                    <span v-html="$root.highlight(a.NCSU_LONG_NAME, search.toLowerCase())"></span> <i v-if="a.NOTE_TEXT > ''" class="has-tooltip text-primary fas fa-lg fa-info-circle"></i>
                    <button type="button" v-if="$parent.pledgeExists(a)" disabled="disabled" class="float-end btn btn-sm btn-info"><i class="far fa-star"></i> Pledge exists</button>    
                    <button type="button" v-else-if="!$parent.inCart(a)" v-on:click="$parent.addToCart(a)" class="float-end btn btn-sm btn-success"><i class="fas fa-plus"></i> Add to cart</button>    
                    <button type="button" v-else-if="$parent.inCart(a)" v-on:click="$parent.removeFromCart(a, i)" class="float-end btn btn-sm btn-danger"><i class="fas fa-ban"></i> Remove from cart</button>
                </li>                                
            </ul>
            <div v-else class="alert alert-primary ms-2 me-2"><i class="fas fa-info-square"></i> No funds match your search criteria</div>
            <div v-if="paginatedData.length" class="card-footer">
                <div class="row">
                    <div class="col-3">
                        <button class="btn btn-primary" type="button" :disabled="pageNumber == 0" v-on:click="prevPage()"><i class="fas fa-chevron-left"></i> Back</button>
                    </div>
                    <div class="col-6" style="text-align: center">
                        <span v-if="pageCount">
                            page {{pageNumber + 1}} of {{pageCount}}
                        </span>
                    </div>
                    <div class="col-3">
                        <button class="float-end btn btn-primary" type="button" :disabled="pageNumber == pageCount - 1 || !pageCount" v-on:click="nextPage()">Next <i class="fas fa-chevron-right"></i></button>
                    </div>
                </div>
            </div>
        </div>
        <span id="search-announce" aria-live="assertive"></span>
    </div>
</template>
<script>
import { Collapse } from 'bootstrap';
export default {   
   components: {       
   },
   data() {        
        return {
            search: '',
		    size: 5,
            pageNumber: 0,
            other: null
        }
    },
    methods: {
        nextPage: function() {
            if (this.pageNumber < this.pageCount) {
                this.pageNumber++;
            }
        },        
        prevPage: function() {
            if (this.pageNumber > 0) {
                this.pageNumber--;
            }
        },
        showSearch: function() {
            if (this.search > '') {
                this.other.show()                
                $('#search-announce').text = "Loading fund search results";
            } else {
                this.other.hide();
                $('#search-announce').text = "Cleared fund search results";
            }
        },               
   
    },
    computed: {
        filteredAllocations: function() {
            var s = this.search.toLowerCase();            
            return this.$parent.otherAllocations.filter(a => {               
                return a.NCSU_LONG_NAME.toLowerCase().includes(s);
            });
        },
        pageCount: function() {
            let l = this.filteredAllocations.length;
            let s = this.size;
            var c = Math.floor(l/s);
            if (l % s > 0) {
                c++;
            }					
            return c;
        },
        paginatedData: function() {            
            if (this.pageNumber > this.pageCount) {                
                this.pageNumber = 0;
            }
            const start = this.pageNumber * this.size;            
            const end = start + this.size;
            return this.filteredAllocations.slice(start, end);
        }
    },
    mounted: function() {
        this.other = new Collapse(document.getElementById('allocationCollapse'), {toggle: false});
    }
}
</script>