<template>
    <input ref="inputRef" type="text" />
  </template>
  
  <script>
  import { useCurrencyInput } from 'vue-currency-input';
  import { watch } from 'vue';
  import { watchDebounced } from '@vueuse/core';
  
  export default {
    name: 'DebouncedCurrencyInput',
    props: {
      modelValue: Number, // Vue 2: value
      options: Object
    },
    setup (props, { emit }) {
      const { inputRef, setValue, numberValue } = useCurrencyInput(props.options, false);
  
      watch(
        () => props.modelValue, // Vue 2: props.value
        (value) => {
          setValue(value)
        }
      );
  
      watchDebounced(numberValue, (value) => emit('update:modelValue', value), { debounce: 500 }) // Vue 2: emit('input', value)
  
      return { inputRef }
    }
  }
  </script>