<template>
<div class="container">
    <div class="row border-bottom pb-2 mb-2" id="heading">
        <div class="col-12">
            <h1 class="display-5 d-none d-sm-block"><i class="text-danger fad fa-envelope-open-dollar"></i> NC State Employee Payroll Deduction Portal</h1>
        </div>
    </div>
    <div v-if="loading">
        <h3 class="text-center"><i class="fad fa-circle-notch fa-spin"></i> Loading, please wait...</h3>
    </div>
    <div v-else>           
        <div v-if="step == 1" key="step1">        
            <div class="row">                     
                <!-- No Lobo ID, show warning -->
                <div v-if="$root.user.loboId == '' || $root.user.loboId == null" class="jumbotron bg-secondary-subtle" style="padding: 2rem">
                    <h2 class="mb-2 pb-2 border-bottom"><i class="text-warning fas fa-exclamation-triangle"></i> Your information was not found</h2>
                    <h4>You could be seeing this message for the following reasons:</h4>
                    <p class="ms-2 text-danger"><i class="fas fa-arrow-right" aria-hidden="true"></i> Are you not a full-time University employee?</p>
                    <p class="ms-2 text-danger"><i class="fas fa-arrow-right" aria-hidden="true"></i> Have you recently begun employment at the University?<br/>
                    <small class="ps-4 text-dark">It may take up to 10 business days for your information to be added to our systems.</small></p>	
                    
                    <p class="border-top pt-2 mb-2">If you are receiving this message in error, please contact Advancement Services using the link below.</p>
                    <div class="mb-3"><a class="btn btn-primary" href="mailto:giftsandrecords@ncsu.edu?subject=Unable to create payroll deduction" role="button"><i class="fas fa-envelope" aria-hidden="true"></i> Contact Advancement Services</a></div>
                    
                    <p class="border-top pt-2">If you would like to submit a payroll deduction pledge manually, you can download the printable form <a href="https://campaign.ncsu.edu/wp-content/uploads/2020/11/FY17-NCSU-payroll-deduction-form-fillable-updated-4.18.17.pdf">here</a>.</p>
                </div>
                <!-- USer has an advance ID, show user info and allow new pledge -->
                <div v-else>
                    <div class="col-12 mt-2">
                        <h3>Hello, our system identified you as <strong>{{unescapeName}}</strong>.  Your employee ID is <strong>{{$root.user.emplId}}.</strong></h3>      
                        <p class="ms-2"><i class="text-primary fas fa-question-circle"></i> <em>Is this information incorrect?  Please <a href="mailto:giftsandrecords@ncsu.edu?subject=Incorrect name or employee ID in payroll deduction portal">contact us</a> so we can correct it.</em></p>  
                    </div>
                    <div class="col-12 mt-1 mb-1">            
                        <div class="alert alert-warning alert-dismissible" role="alert">
                            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                            <i class="fad fa-lg pe-1 fa-bell-on"></i> Any changes made between <strong>{{cutoff.start}}</strong> and <strong>{{cutoff.end}}</strong> will be reflected in 
                            <strong v-if="$root.user.newHire == 'N'">{{cutoff.month}}'s</strong><strong v-else>{{cutoff.newEmpMonth}}'s</strong>
                            paycheck.                            
                        </div>
                    </div>
                    <!-- Create a new pledge -->
                    <div class="col-12 pb-3">
                        <button type="button" v-on:click="step = '2'; donation.action = 'add'" class="btn btn-lg btn-success"><i class="fad fa-envelope-open-dollar"></i> Create a new payroll deduction pledge</button>
                    </div>   
                    
                    <!-- Edit an existing pledge -->
                    <div class="col-12 border-top pt-3 mt-3">
                        <h3>My payroll deduction pledges</h3>
                        <p><em>This listing does not include deductions for the North Carolina State Employees Combined Campaign or other areas of NC State. <a href="https://controller.ofa.ncsu.edu/payroll/" target="_blank">Contact NC State Payroll</a> 
                        to manage other deductions.</em></p>
                    </div>
                    <div class="col-12">               
                        <!-- No payroll pledges -->
                        <div v-if="pledges.length == 0">
                            <div class="alert alert-info" role="alert">                        
                                <i class="fas fa-lg pe-1 fa-envelope"></i> You don't have any payroll deduction pledges.                            
                            </div>
                        </div>
                        <!-- User has payroll pledges -->
                        <div v-else>           
                            <ul class="list-group mb-3">
                                <li class="list-group-item" :key="i" v-for="(p, i) in pledges">
                                    <div class="row">
                                        <div class="col-lg-8 col-md-12">                                                   
                                            <h5>{{p.DESIGNATION_DESCRIPTION}}</h5>
                                            <div v-if="p.PLEDGE_TYPE == 'sustaining'">
                                                {{ $root.currency(Number(p.DESIGNATION_AMOUNT))}} monthly, until changed
                                            </div>
                                            <div v-if="p.PLEDGE_TYPE == 'recurring'">
                                                {{ $root.currency(Number(p.DESIGNATION_AMOUNT))}} monthly, {{ $root.currency(Number(p.PLEDGE_AMOUNT))}} total, ending {{ p.PLEDGE_END_DATE }}
                                            </div>
                                            <div>
                                                Last changed on {{p.PLEDGE_DATE_MODIFIED_DISPLAY}}
                                            </div>                                                                                              
                                        </div>
                                        <div class="col-lg-4 col-md-12 mt-3 mt-lg-0">
                                            <div class="float-lg-end">                                                    
                                                <button type="button" v-on:click="updatePledge(p)" class="btn btn-sm btn-info text-white me-2"><i class="fas fa-pencil"></i> Update pledge</button>
                                                <button type="button" v-on:click="setPledge(p)" class="btn btn-sm btn-danger"><i class="fas fa-ban"></i> Cancel pledge</button>
                                            </div>
                                        </div>
                                        <div v-if="p.FLAG == 'PENDING'" class="col-12 col-xl-6">
                                            <div class="alert alert-warning py-1 px-2 mb-0 mt-1">
                                                <i class="fad fa-hourglass-start"></i> <span>This is a pending pledge; no payments have been made yet.</span>
                                            </div>
                                        </div>                                             
                                    </div>
                                </li>
                            </ul>                                                   
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="step == 2" key="step2">                
            <div class="row pb-2 border-bottom">
                <div class="col-8">
                    <h3 class="my-0">{{actionHeading}}</h3>                    
                </div>
                <div class="col-4">
                    <button type="button" class="btn btn-primary float-end" v-on:click="step = '1'"><i class="fas fa-arrow-left"></i> Go back</button>
                </div>
            </div>
            <div class="row">
                <div class="col mt-4">
                    <h4>Choose your area of support</h4>
                    <p>Choose a fund to give to by selecting it from the list below. If you can't find the fund you wish to give to in the list, you can search for it in the '<a href="" v-on:click.prevent="scrollTo('headingTwo')">Search all funds</a>' section. 
                    Add it to your cart, then choose how much you'd like to give. You can give to as many funds as you'd like, but the total given to each fund must be at least $5.00.</p>
                </div>
            </div>
            
            <!-- Featured funds -->
            <div class="row">     
                <div class="col-12">           
                    <div class="card card-default">
                        <div class="card-header" id="headingOne">
                            <h3 class="pt-1 ps-2">
                                <i class="text-danger far fa-star"></i> Featured funds
                            </h3>
                        </div>
                        <div id="featuredFunds">			
                            <div class="card-body p-2">
                                <div class="col-lg-6 col-md-12 pb-2" :class="{'float-start ps-2 pe-2': k % 2 == 0, 'float-end ps-2 pe-2': k % 2 != 0}" :key="k" v-for="(category, k) in categories">
                                    <h4>{{category}}</h4>
                                    <ul class="list-group"> 	
                                        <li class="list-group-item clearfix" :key="i" v-for="(a, i) in featuredAllocations.filter(alloc => {return alloc.CATEGORY_NAME == category})">      
                                            <p 
                                                class="float-start mb-0">{{a.LONG_NAME}} 
                                                <tooltip :data="a.NOTE_TEXT"></tooltip>
                                            </p>                                                                              
                                            <button type="button" v-if="pledgeExists(a)" disabled="disabled" class="float-end btn btn-sm btn-info"><i class="far fa-star"></i> Pledge exists</button>    
                                            <button type="button" v-else-if="!inCart(a)" v-on:click="addToCart(a)" class="float-end btn btn-sm btn-success"><i class="fas fa-plus"></i> Add to cart</button>    
                                            <button type="button" v-else-if="inCart(a)" v-on:click="removeFromCart(a, i)" class="float-end btn btn-sm btn-danger"><i class="fas fa-ban"></i> Remove from cart</button>                                  
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>      
                </div>          
            </div>            
            <!-- / Featured funds -->
            <!-- Other funds -->
            <div class="row">   
                <div class="col-12">
                    <div class="card card-default">
                        <div class="card-header" role="tab" ref="headingTwo" id="headingTwo">
                            <h3 class="pt-1 ps-2">
                                <i class="text-danger far fa-search"></i> Other funds
                            </h3>
                        </div>
                        <allocation-search></allocation-search>
                    </div>
                </div>
            </div>
            <!-- / Other funds -->
            <!-- Cart -->
            <div class="row">            
                <div class="col-12">
                    <p><em>All funds donated are managed by one of the University's <a href="http://foundationsaccounting.ofa.ncsu.edu/foundations/" target="_blank">affiliated foundations</a>.</em></p>
                </div>
                
                <div class="col-12">
                    <!-- Begin cart -->	
                    <div class="card card-primary cart">
                        <div class="card-header">
                            <div class="card-title">                                
                                <div class="row">
                                    <div class="col-10">
                                        <h3 class="ps-2 pt-2"><i class="fas fa-shopping-cart" aria-hidden="true"></i> Your Cart</h3>
                                    </div>
                                    <div class="col-2">
                                        <h4 class="float-end me-2 mt-2"><span class="badge badge-secondary">{{donation.cart.length}} fund<span v-if="donation.cart.length != 1">s</span></span></h4>
                                    </div>
                                </div>
                            </div>
                        </div> <!-- /card-header -->
                        
                        <div class="card-body" v-if="!donation.cart.length">
                            <div class="p-3">No funds have been added, yet.</div>
                        </div>
                        
                        <div v-if="cartError" class="alert alert-danger m-2" role="alert">			
                            <i class="fas fa-exclamation" aria-hidden="true"></i> The minimum donation to a fund is $5.00.
                        </div>
                        <ul class="list-group cart-list" style="margin-bottom: 0px" v-if="donation.cart.length">
                            <li class="list-group-item cart-item" :key="i" v-for="(fund, i) in donation.cart">
                                <div class="row">
                                    <div class="cart-fund col-xs-12 col-sm-10">
                                        <button v-on:click="removeFromCart(fund, i)" type="button" class="btn btn-danger btn-sm me-2"><i class="fas fa-lg fa-times"></i></button>
                                        <span>{{fund.LONG_NAME}}</span>                                                                                
                                    </div>
                                    <div class="col-xs-12 col-sm-2">
                                        <div class="input-group">                                                
                                            <span class="input-group-text" :class="{'bg-danger border-danger text-white': fund.error}"><i class="fas fa-fw" :class="{'fa-exclamation' : fund.error, 'fa-dollar-sign': !fund.error}"></i></span>                                            
                                            <currency-input class="form-control" placeholder="0.00" v-model="fund.amount" :options="currencyInputOptions" />                                            
                                        </div>
                                    </div>            
                                </div>
                            </li>
                        </ul>                               
                        <div class="card-footer">                            
                            <div class="row">
                                <div class="col-xs-12 col-md-8">
                                    <div class="alert alert-info" style="margin-bottom: 0px">
                                        <h3><i class="fas fa-calendar" aria-hidden="true"></i> My deduction should:</h3>
                                        <div class="row">
                                            <div class="col-12 form-check radio">
                                                <input class="me-2" type="radio" v-model="donation.type" id="sustaining" value="sustaining"> 
                                                <label for="sustaining">continue monthly until I change it</label>
                                            </div>
                                            <div class="col-12 form-check radio">
                                                <div class="row mt-2">
                                                    <div class="col-auto pt-1">
                                                        <input class="me-2 radio" type="radio" v-model="donation.type" id="recurring" value="recurring"> 
                                                        <label for="recurring">stop after</label>
                                                    </div>
                                                    <div class="col-auto">
                                                        <select class="ms-2 me-2 form-select" :disabled="donation.type != 'recurring'" v-model="donation.duration">
                                                            <option :key="i" v-for="(p, i) in numberOfPayments" :value="p">{{p}}</option>
                                                        </select>                                                          
                                                    </div>
                                                    <div class="col-auto pt-1"> months</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>    
                                <div class="col-xs-12 col-md-4">
                                    <div class="row">
                                        <div class="col-xs-4">
                                            <div class="h4">Total:</div>
                                        </div>
                                        <div class="h4 col-xs-8">
                                            <p><strong>{{$root.currency(cartTotal(1))}}</strong> monthly</p>
                                            <p v-if="donation.type == 'recurring'"><strong>{{$root.currency(cartTotal(donation.duration))}}</strong> in total</p>                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>    
                    </div>                                    
                </div>
            </div>        
            <!-- End cart -->
            <div class="row">
                <div class="col-12">
                    <button :disabled="!donation.cart.length || cartError || cartTotal(1) == 0" v-on:click="step = '3'" class="btn btn-primary float-end">Next <i class="fas fa-arrow-right"></i></button> 
                </div>
            </div>      
        </div>
        <!-- step 3 -->
        <div v-if="step == 3" key="step3">
            <div class="row">
                <div class="col-12">
                    <h3>{{actionHeading}}</h3>                    
                </div>
            </div>
            <div class="row">
                <div class="col-12 mt-4">
                    <h4>Verify your pledge information</h4>
                </div>
                <div class="col-12 mt-1 mb-1">            
                    <div class="alert alert-warning" role="alert">
                        <i class="fad fa-lg pe-1 fa-bell-on"></i> Please note that your first deduction will be reflected in 
                            <strong v-if="$root.user.newHire == 'N'">{{cutoff.month}}'s</strong><strong v-else>{{cutoff.newEmpMonth}}'s</strong>
                            paycheck.                        
                    </div>
                </div>
                <div class="col-12 mt-1 mb-2 border-bottom">
                    <h3>Please review the following information to ensure it is accurate.</h3>
                </div>
                <div class="col-12">
                    <div v-if="pledgeAction == 'modify'" class="border-bottom pb-2 mb-3">
                        <h4><i class="fad text-primary fa-money-check-edit-alt"></i> Original pledge</h4>
                        <div class="pt-2 ms-2">                                
                            <div class="row mb-1">  
                                <label class="col-sm-2 control-label"><strong>Donation type</strong></label>
                                <div class="col-sm-10">
                                    <span v-if="donation.toModify.PLEDGE_TYPE == 'sustaining'">Open-ended pledge; my monthly pledge will continue until I change it</span>
                                    <span v-if="donation.toModify.PLEDGE_TYPE == 'recurring'">Recurring pledge; my monthly pledge will continue for <strong>{{donation.toModify.DESIGNATION_NUMBER_OF_PAYMENTS}}</strong> months</span>            
                                </div>
                            </div>                                

                            <div class="row">
                                <label class="mb-0 col-sm-2 control-label"><strong>Selected fund</strong></label>                                     
                                <div class="col-sm-7">{{donation.toModify.DESIGNATION_DESCRIPTION}}</div>                            
                                <div class="col-sm-3">
                                    <strong>{{$root.currency(Number(donation.toModify.DESIGNATION_AMOUNT))}}</strong> 
                                    <span v-if="donation.toModify.PLEDGE_TYPE == 'recurring'"><strong>, {{$root.currency(Number(donation.toModify.PLEDGE_AMOUNT))}}</strong> in total</span>                                                            
                                </div>
                            </div>
                            
                        </div>
                    </div>

                    <h4 v-if="pledgeAction == 'modify'"><i class="fad text-success fa-money-check-edit-alt"></i> Updated pledge</h4>
                    <div class="pt-2 ms-2">
                        <div class="row mb-1">  
                            <label class="col-sm-2 control-label"><strong>Donation type</strong></label>
                            <div class="col-sm-10">
                                <span v-if="donation.type == 'sustaining'">Open-ended pledge; my monthly pledge will continue until I change it</span>
                                <span v-if="donation.type == 'recurring'">Recurring pledge; my monthly pledge will continue for <strong>{{donation.duration}}</strong> months</span>            
                            </div>
                        </div>
                    
                        <div class="row mb-1" :key="i" v-for="(f, i) in donation.cart.filter(c => {return c.amount > 0})">
                            <label v-if="i == 0" class="mb-0 col-sm-2 control-label">                                
                                <span><strong>Selected funds</strong></span>
                            </label>
                            <div class="col-sm-7" :class="{'offset-sm-2': i > 0}">{{f.LONG_NAME}}</div>
                            <div class="col-sm-3"><strong>{{$root.currency(Number(f.amount))}}</strong></div>
                        </div>
                    
                        <hr/>
                        <div class="row h5 pb-2">
                            <label class="col-sm-2 control-label"><strong>Total donation</strong></label>
                            <div class="col-sm-3 offset-sm-7">
                                <strong>{{$root.currency(cartTotal(1))}}</strong> monthly
                                <span v-if="donation.type == 'recurring'"><strong>, {{$root.currency(cartTotal(donation.duration))}}</strong> in total</span>                            
                            </div>
                        </div>                            
                    </div>
                    <div class="alert alert-info">
                        <input type="checkbox" class="form-check-input float-start" v-model="donation.approve" value="yes" id="payrollApprove" required>
                        <label for="payrollApprove" class="form-check-label ms-2 mb-0">
                            I authorize the University Payroll Office to deduct the amount indicated from my pay each pay period.
                        </label>        
                    </div>                    
                </div>
            </div>
            <div class="row">
                <div class="col-6">
                    <button v-on:click="step = '2'" class="btn btn-primary float-start"><i class="fas fa-arrow-left"></i> Back</button> 
                </div>
                <div class="col-6">                    
                    <button v-on:click="submitPledge(); step = '4'" :disabled="!donation.approve" class="btn btn-primary float-end">Submit <i class="fas fa-arrow-right"></i></button> 
                </div>
            </div>
        </div>
        <!-- step 4 -->
        <div v-if="step == 4" key="step4">           
            <div class="row">
                <div v-if="pledgeError.error" class="col-12">                    
                    <h3 class="text-danger"><i class="fas fa-exclamation-circle" aria-hidden="true"></i> There was a problem processing your pledge</h3>
                    <div class="alert alert-danger" role="alert">
                        {{pledgeError.data}}
                    </div>   
                    <p>This may be a temporary error, if you wish to start over and try again, please click the button below.</p>
                    <p><button type="button" class="btn btn-primary" v-on:click="step = 1"><i class="fas fa-repeat-alt"></i> Start over</button></p>                 
                    <p>If this error persists, please contact NC State Gifts and Records Management by phone at 919.515.7827 or by email at <a href='mailto:giftsandrecords@ncsu.edu'>giftsandrecords@ncsu.edu</a> for assistance.</p>
                </div>
                <div v-else class="col-12">                    
                    <h3>Thank you for your pledge!</h3>
                    <p>
                        Thank you, your payroll deduction pledge has been submitted for processing. An email confirmation will be mailed to your ncsu.edu account shortly. Your first deduction will be reflected in 
                        <strong v-if="$root.user.newHire == 'Y'">{{cutoff.newEmpMonth}}'s</strong>
                        <strong v-else>{{cutoff.month}}'s</strong> paycheck.
                    </p>

                    <p>To change or stop your payroll deduction pledge, visit the <a :href="homeUrl">Payroll Deduction portal</a>.</p>
                </div>
            </div>            
        </div>        
    </div>
    <pledge-delete :pledge="currentPledge"></pledge-delete>
</div>
</template>
<script>

const donationDefault = {type: 'recurring', duration: '12', action: '', cart: [], approve: false};
const currencyInputOptions = {
  "locale": "en-US",
  "currency": "USD",
  "currencyDisplay": "hidden",
  "hideCurrencySymbolOnFocus": true,
  "hideGroupingSeparatorOnFocus": true,
  "hideNegligibleDecimalDigitsOnFocus": false,
  "autoDecimalDigits": true,
  "useGrouping": true,
  "accountingSign": false
};


import pledgeDelete from './pledge.delete.vue';
import currencyInput from '../common/currencyInput.vue';
import tooltip from './tooltip.vue';
import allocationSearch from './allocation.search.vue';
export default {   
   components: {
       pledgeDelete: pledgeDelete,
       currencyInput: currencyInput,
       tooltip: tooltip,
       allocationSearch: allocationSearch       
   },
   data() {        
        return {
            amountTimeout: false,           
            categories: [],
            cartFunds: [],
            currentPledge: {},
            cutoff: {start: '', end: '', month: '', newEmpMonth: ''},
            currencyInputOptions: currencyInputOptions,
            donation: donationDefault,           
            featuredAllocations: [],
            loading: false,            
            loggedIn: false,  
            modalPledgeDelete: null,          
            numberOfPayments:  ['2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '36', '48', '60'],
            otherAllocations: [],
            pledgeAction: 'new',
            pledgeError: {error: false, data: ''},
            pledges: [],
            pledgeSubmitting: false,
            step: '1',
            user: {}
        }
    },
    methods: {
        addToCart: function(fund) {                             
            var f = JSON.parse(JSON.stringify(fund));   
            f.amount = '0';
            if (fund.amount) {                     
                f.amount = fund.amount;
            }            
            f.dirty = false;
            f.error = false;            
            this.donation.cart.push(f);
        },        
        cartTotal: function(frequency) {
            var total = 0;
            this.donation.cart.forEach(function(item) {
                if (isNaN(item.amount) || item.amount == '') {
                    total += 0;
                } else {
                    total += parseFloat(item.amount);
                }                
            });                 
            return Math.round(total * (100 * frequency)) / 100;
        },
        testAmount: function(fund){
            fund.dirty = true;
            if (isNaN(fund.amount)) {
                fund.amount = 0;
            }
            fund.amount = Math.round(parseFloat(fund.amount) * 1e2) / 1e2;                                
            if (fund.amount < 5 && fund.amount > 0) {                
                fund.error = true;
            } else {
                fund.error = false;
            }              
        },                
        formatAmount: function(value) {
            let val = (value/1).toFixed(2).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")        
        },
        getData: function() {
            if (this.$root.user.loboId == '' || this.$root.user.loboId == null) {
                return;
            }
            var _this = this;
            this.loading = true;            
            var promises = [];                       
            promises.push(this.axios.get(this.$config[process.env.VUE_APP_ENV].app.base+'data/php/payroll/allocations.get.php')); //All allocations
            promises.push(this.axios.get(this.$config[process.env.VUE_APP_ENV].app.base+'data/php/payroll/donor.pledges.get.php', { //Donor pledges
                params: {
                    'contactId': _this.$root.user.loboId                  
                }
            }));
            promises.push(this.axios.get(this.$config[process.env.VUE_APP_ENV].app.base+'data/php/payroll/cutoff.get.php'));
            Promise.all(promises).then(response => {                              
                for (let i = 0; i < response.length; i++) {
                    if (response[i].data.error) { //All allocations
                        _this.$root.setAlert(true, response[i].data.error, 'alert-danger');
                        _this.loading = false;
                        return;
                    } 
                }        
                _this.allAllocations = response[0].data;        
                _this.featuredAllocations = _this.allAllocations.filter(a => { return a.SORT_ORDER != null});                
                for (let j = 0; j < _this.featuredAllocations.length; j++) {
                    if (!(_this.categories.includes(_this.featuredAllocations[j].CATEGORY_NAME))) {                        
                        _this.categories.push(_this.featuredAllocations[j].CATEGORY_NAME);
                    }
                }
                _this.otherAllocations = _this.allAllocations.filter(a => { return a.SORT_ORDER == null});                
                _this.pledges = response[1].data;                           
                _this.cutoff = response[2].data;                   
                _this.loading = false;
            });
        },
        inCart: function(fund) {
            var out = false;
            let f = this.donation.cart.filter(f => {
                return f.ALLOCATION_CODE == fund.ALLOCATION_CODE;
            });
            out = f.length;
            return out;
        },
        pledgeExists: function(allocation) {            
            var exists = false;
            if (this.pledges.filter(p => {
                return p.ALLOCATION_CODE == allocation.ALLOCATION_CODE;
            }).length > 0) {
                exists = true;
            }
            return exists;
        },
        removeFromCart: function(fund) {
            this.donation.cart = this.donation.cart.filter(f => {
                return f.ALLOCATION_CODE != fund.ALLOCATION_CODE;
            });
        },         
        scrollTo: function(refName) {
            var element = this.$refs[refName];
            var top = element.offsetTop;
            window.scrollTo(0, top);
        },  
        setPledge: function(pledge) {            
            this.currentPledge = pledge;
            this.modalPledgeDelete.show();
        },
        submitPledge: function() {
            var _this = this;
            this.pledgeError = {error: false, data: ''};
            this.pledgeSubmitting = true;           

            var data = {                
                user: this.$root.user,
                pledge: this.donation,
                cutoff: this.cutoff				
			};	
			var formData = new FormData();
			formData.append("data", JSON.stringify(data));
            this.axios({
                method: 'post',
                url: this.$config[process.env.VUE_APP_ENV].app.base+'data/php/payroll/pledge.update.php',                
                data: formData
            }).then(response => {	                
                _this.pledgeSubmitting = false;                
                _this.donation = donationDefault;
				if (response.data.error) {
					_this.pledgeError = {error: true, data: response.data.error};
					return;
				} else {
                    _this.pledges = response.data[0];                    
                }
    		}).catch(error => {
				_this.$root.setAlert(true, error, 'alert-danger');
				return;
    		}); 
        },        
        updatePledge: function(pledge) {                      
            this.pledgeAction = 'modify';           
            this.donation = JSON.parse(JSON.stringify(donationDefault));

            var fund = {};

            /*
            this.pledgedAllocations = this.pledges.filter(p => {
                return p.DESIGNATION_EXTERNAL_ID != pledge.DESIGNATION_EXTERNAL_ID;
            });            
            */

            let f = this.allAllocations.filter(a => {               
                return a.ALLOCATION_CODE == pledge.DESIGNATION_EXTERNAL_ID;
            });

            if (f.length) {
                fund = f[0];
            }

            fund.amount = pledge.DESIGNATION_AMOUNT;
            //Add the toModify object to the donation object, when the pledge is posted, this will become the pledge to be deleted based on the 'update' action flag
            this.donation.toModify = pledge;
            //Fetch type and duration from modified transaction
            this.donation.type = pledge.PLEDGE_TYPE;
            if (this.donation.type == 'recurring') { //Set a conditional here so P type doesn't set the duration to nothing and break the dropdown
                this.donation.duration = pledge.DESIGNATION_NUMBER_OF_PAYMENTS;         
            }
            //Set action to update (default is 'add'), update status both adds a new pledge and sets the old one as paid
            this.donation.action = 'update';              
            this.addToCart(fund); 
            this.step = '2';                   
        }
    },
    computed: {     
        actionHeading: function() {
            var out = '';
            switch (this.pledgeAction) {
                case 'new':
                    out = 'Create a new payroll deduction pledge';                
                break;
                case 'modify':
                    out = 'Update your payroll deduction pledge';
                break;
            }
            return out;
        },
        activePledges: function() {
            return this.pledges.filter(p1 => {return p1.FLAG == 'PROCESSED'});
        },
        cartError: function() {
            var out = false;
            if (this.donation.cart.filter(e => {return e.error == true;}).length) {
                out = true;
            }
            return out;
        },            
        homeUrl: function() {
            return this.$config[process.env.VUE_APP_ENV].uri.app + 'payroll/';
        },
        pendingPledges: function() {
            return this.pledges.filter(p1 => {return p1.FLAG == 'PENDING'});
        },         
        unescapeName: function() {
            return this.$root.user.name.replace(/\+/g, ' ');
        }        
    },
    watch: {
       'donation.cart': {
            deep: true,
            handler: function() {            
                for (var i = 0; i < this.donation.cart.length; i++) {
                    this.testAmount(this.donation.cart[i]);
                }
            }
        }
    },
    mounted: function() {
        this.$root.isLoggedIn();
        this.getData();
    }
}
</script>
<style scoped>
/*Safari fix */
.row:before, .row:after {
    width:0px; height:0px;
}
.container {
    padding: 60px 0px 60px;
}

h2 {
    font-family: 'Roboto';    
}

h3 {
    font-size: 1.7rem;
    font-family: "Roboto Slab",serif;       
}

.display-5 {
    font-size: 2.8rem;
    font-weight: 300;
    line-height: 1.2;
}

#modalPledgeDelete {
    top: 20%;
}

.fade-enter-active, .fade-leave-active {
  transition: all .3s ease;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateX(100px);
}

.fade-leave, .fade-enter-to {
    opacity: 1;
    transform: translateX(0);
}

.search-highlight {
    background-color: #ffc107;
    color: #343a40;
}

.radio {
    font-size: 1.2rem;
}

input[type=radio], input[type=checkbox] {    
    transform: scale(1.3);
}

input[type=radio] {    
    margin-top: 0px;
}

input[type=checkbox] {    
    margin-top: 5px;
    margin-right: 10px;
}

.btn {
    border-radius: .25rem;
}

.list-group {
    position: relative;
    display: block;
    padding: 0rem;    
    background-color: #fff;
    border-bottom: 1px solid rgba(0,0,0,.125);
}

#otherAllocations > .list-group {
    border: none;
}

.card.card-default {
    border-color: #ddd;
}

.card-default>.card-header {
    color: #333;
    background-color: #f5f5f5;
    border-color: #ddd;
    border-bottom: 1px solid rgba(0,0,0,.125);
}

.card.card-primary {
    border-color: #c00;
}

.card.card-primary > .card-header {    
    background-color: #C00;
}

.cart .card-header h3 {
    color: #FFF;
}

.input-group > input {
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
}

.cart .input-group-prepend .input-group-text {
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
}

.cart .input-group > input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
}


.card {
    margin-bottom: 22px;
    background-color: #fff;
    border: 1px solid transparent;
    border-radius: 1px;    
}
.list-group-item {    
    padding: .75rem 1.25rem;    
    background-color: #fff;
    border-bottom: 1px solid rgba(0,0,0,.125);
}

.list-group-item:last-child {
    border-bottom: none;
}

.list-group-item > .btn {
    margin-top: -4px;
}

.list-group.cart-list {
    border: none;
}

.list-group-item.cart-item {
    border-radius: 0;
}

.list-group-item p {
    max-width: 380px;
}

.cart .card-footer {
    padding: 10px 15px;
    background-color: #f5f5f5;
    border-top: 1px solid #ddd;
}

.tooltip-inner {
    font-weight: normal;
}

h4.text-dark {
    font-weight: bold;
}

</style>