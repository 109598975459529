<template>
    <i v-if="data > ''" 
        class="has-tooltip fas text-primary fa-lg fa-info-circle" 
        data-bs-toggle="tooltip" data-bs-placement="right"
        :title="data"
    ></i>  
</template>
<script>
import { Tooltip } from 'bootstrap';
export default {
    props: ['data'],
    mounted: function() {        
        Array.from(document.querySelectorAll('*[data-bs-toggle="tooltip"]')).forEach(tooltipNode => new Tooltip(tooltipNode));
    }
}
</script>
<style scoped>
    i.has-tooltip:hover {
        cursor: pointer;
    }
</style>