<template>
     <div class="redirect d-flex align-items-center min-vh-100">
        <div class="text-white box w-100 text-center">
            <h1>
                <i class="fas fa-circle-notch fa-spin"></i> Please wait while you are redirected...
            </h1>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {            
            gcUrl: ['https://www.givecampus.com/campaigns/', '/donations/new?a='],
            encompassAllocations: [],
            giveCampusMap: [                
                {area: "/", page: "47387", appeal: "8830926"},
                {area: "/cals", page: "53663", appeal: "8814225"},
                {area: "/design", page: "53687", appeal: "8814350"},
                {area: "/education", page: "53688", appeal: "8814424"},
                {area: "/engineering", page: "53690", appeal: "8814547"},
                {area: "/chass", page: "53694", appeal: "8814818"},
                {area: "/pcom", page: "53695", appeal: "8814879"},
                {area: "/cnr", page: "53697", appeal: "8814930"},
                {area: "/sciences", page: "53698", appeal: "8815072"},
                {area: "/vetmed", page: "53700", appeal: "8815121"},
                {area: "/textiles", page: "53701", appeal: "8815166"},
                {area: "/dasa", page: "53702", appeal: "8815213"},
                {area: "/athletics", page: "53703", appeal: "8815248"},
                {area: "/arts", page: "53704", appeal: "8815294"},
                {area: "/caldwellfellows", page: "53774", appeal: "8830182"},
                {area: "/graduate", page: "53705", appeal: "8815360"},
                {area: "/iei", page: "53775", appeal: "8830227"},
                {area: "/libraries", page: "53707", appeal: "8815381"},
                {area: "/parkscholars", page: "53781", appeal: "8830354"},
                {area: "/sheltonleaderhip", page: "53782", appeal: "8830412"},
                {area: "/music", page: "53858", appeal: "8839527"},
                {area: "/jcra", page: "49479", appeal: "9409833"},
                {area: "/fol", page: "50179", appeal: "9409834"}
            ]
        }
    },
    mounted() {        
        switch (this.$route.name) {
            case 'purl': 
                this.redirectPurl();
            break;
            case 'encompass':
                $('.container').hide();                
                this.redirectEncompass();                
            break;
            default: {
                $('.container').hide();
                this.redirectGive();                
            }
        }        
    },
    methods: {                
        redirectGive: function() {
            var url = '';
            var area = "/";   
            if (this.$route.params.area > '') {
                area = area + this.$route.params.area;
            }
    
            var site = this.giveCampusMap.filter(d => { return d.area == "/"; })[0]; //Default to AG site 
            var gc = this.giveCampusMap.filter(g => { return g.area == area; });  
            if (gc.length > 0) {
                site = gc[0];
            }        
            url = this.gcUrl[0] + site.page + this.gcUrl[1] + site.appeal;
            window.location = url;
        },
        redirectEncompass: function() {
            var qDids = this.$route.query.dids;
            var qAmt = this.$route.query.amt;

            var designations = '';
            var encompassFunds = [];
            var value = 0;
            var site = this.giveCampusMap.filter(d => { return d.area == "/"; })[0]; //Default to AG site 
            var url = this.gcUrl[0] + site.page + this.gcUrl[1] + site.appeal;

            this.axios.get(this.$config[process.env.VUE_APP_ENV].app.base+'data/json/all-allocations.json')
            .then(response => {	                                           		
				if (response.data.error) {
					this.$root.setAlert(true, response.data.error, 'alert-danger');
					return;
				} else {                    
                    this.encompassAllocations = response.data;    
                    if (qDids) {
                        let dids = qDids.split('.');
                        //Get the associated allocation code from the dids
                        for (let i = 0; i < dids.length; i++) {
                            let fund = this.encompassAllocations.filter(f => { return f.ENCOMPASS_ID == dids[i] });
                            //If the dids has an associated allocation, add it to the encompassFunds array
                            if (fund.length > 0) {
                                encompassFunds.push(fund[0].ALLOCATION_CODE);
                            }                            
                        }
                        //If there are encompass funds, add them to the designations query string
                        if (encompassFunds.length > 0) {
                            let l = encompassFunds.length; //Only 5 funds are allowed per link
                            if (l > 5) {
                                l = 5;
                            }
                            for (let i = 0; i < l; i++) {                                    
                                var d = 'designation';
                                if (i > 0) {
                                    d = '&' + d + '_' + i; //designation_1 through designation_4, separated by ampersands
                                }
                                d = d + '=' + encompassFunds[i];
                                designations += d;               
                            }
                        }
                    }
                    if (qAmt) {
                        let amt = qAmt.split('|');
                        let al = amt.length;
                        if (al > 5) { //Only 5 funds are allowed per link
                            al = 5;
                        }
                        for (let i = 0; i < al; i++) {
                            var a = parseFloat(amt[i]);
                            if (Number.isNaN(a)) {
                                a = 0;
                            }
                            value = value + a;
                        }
                    }
                    if (qDids && qAmt) {
                        url = url + '&'+ designations + '&value=' + value;
                    } else {
                        if (qDids) {
                            url = url + '&'+ designations;
                        }
                        if (qAmt) {
                            url = url + '&value=' + value;
                        }
                    }
                    window.location = url;
				}
    		}).catch(error => {
				this.$root.setAlert(true, error, 'alert-danger');
				return;
    		});
        },
        redirectPurl: function() {     
            var p = this.$route.params.purlid;  
            var site = this.giveCampusMap.filter(d => { return d.area == "/"; })[0]; //Default to AG site 
            var url = this.gcUrl[0] + site.page + this.gcUrl[1] + site.appeal;

            var designations = '';            
            //var value = 0;

            if (!p) {
                window.location = url;
            } else {
                //Get pURL data
                this.axios.get(this.$config[process.env.VUE_APP_ENV].app.base+'data/php/purl/purl.get.php', {
                    params: {
                        'purl_id': p            
                    }
                }).then(response => {	                                           		
                    if (response.data.error) {
                        this.$root.setAlert(true, response.data.error, 'alert-danger');
                        return;
                    } else {                   
                        let dids = response.data[0]['DIDS'].split('.');                        
                        //let amount = response.data[0]['BTNVALS'];                                
                        
                        if (dids.length > 0) {
                            let l = dids.length; //Only 5 funds are allowed per link
                            if (l > 5) {
                                l = 5;
                            }
                            for (let i = 0; i < l; i++) {                                    
                                var d = 'designation';
                                if (i > 0) {
                                    d = '&' + d + '_' + i; //designation_1 through designation_4, separated by ampersands
                                }
                                d = d + '=' + dids[i];
                                designations += d;               
                            }
                        }
                                                        
                        //Get the 'middle' button amount from the list of three button amounts
                        /*let a = parseFloat(amount);
                        if (Number.isNaN(a)) { 
                            a = 0;
                        }
                        value = value + a;
                        */
                        
                        if (designations > '') {
                            url = url + '&'+ designations;
                        }

                        /*if (designations > '' && value > 0) {
                            url = url + '&'+ designations + '&value=' + value;
                        } else {
                            if (designations > '') {
                                url = url + '&'+ designations;
                            }
                            if (value > 0) {
                                url = url + '&value=' + value;
                            }
                        }*/
                        window.location = url;
                    }
                }).catch(error => {
                    this.$root.setAlert(true, error, 'alert-danger');
                    return;
                });                              
            }    		         								
        }
    }
}
</script>
<style scoped>
.redirect {
    background-image: url('@/assets/images/gc.jpg');
    background-size: cover; 
    background-position: center;
}
</style>