<template>
    <div class="modal fade" id="modalPledgeDelete" tabindex="-1" role="dialog" aria-labelledby="modalPledgeDelete" aria-hidden="true" data-bs-backdrop="static" data-bs-keyboard="false">        
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">                    
                    <h3><i class="far fa-check-circle text-primary"></i> Confirm your payroll deduction cancellation</h3>            
                    <button type="button" class="btn-close" v-on:click="modalClose()" aria-label="Close"></button>                    
                </div>
                <div class="modal-body">
                    <div class="alert alert-warning"><i class="fad fa-lg fa-exclamation-triangle pe-1"></i> Are you sure you want to cancel the following pledge?</div>
                    <div class="row ms-2">
                        <div class="col-12"><strong>{{pledge.DESIGNATION_DESCRIPTION}}</strong></div>
                        <div class="col-12">
                            <span v-if="pledge.PLEDGE_TYPE == 'sustaining'">Open-ended pledge, monthly payments of <strong>{{$root.currency(pledge.PLEDGE_AMOUNT)}}</strong></span>
                            <span v-if="pledge.PLEDGE_TYPE == 'recurring'">Recurring pledge, <strong>{{pledge.DESIGNATION_NUMBER_OF_PAYMENTS}}</strong> monthly payments of <strong>{{$root.currency(pledge.DESIGNATION_AMOUNT)}}, {{$root.currency(pledge.PLEDGE_AMOUNT)}}</strong> in total</span>            
                        </div>
                    </div>
                </div>
                <div class="modal-footer">    
                    <div class="float-end">                
                        <button 
                            type="button" 
                            class="btn btn-success me-2" 
                            v-on:click="cancelPledge()"
                            :disabled="loading"
                        >
                            <span v-if="loading"><i class="fad fa-circle-notch fa-spin"></i> Please wait...</span>
                            <span v-else><i class="fas fa-check"></i> Yes, cancel this pledge</span>                            
                        </button>                
                        <button type="button" :disabled="loading" class="btn btn-secondary" v-on:click="modalClose()"><i class="fas fa-times"></i> No, do not cancel</button>                
                    </div>
                </div> 
            </div>
        </div>
    </div>
</template>
<script>
import { Modal } from 'bootstrap';
export default {
    props: ['pledge'],
    data() {
        return {
            loading: false,
            pageNumber: 0,
            search: '',
		    size: 5,            
        }
    },
    methods: {
        cancelPledge: function() {
            var _this = this;     
            this.loading = true;               
            var p = Object.assign({}, this.pledge);
            p.action = 'delete';
            var data = {                
                user: this.$root.user,
                pledge: p,
                cutoff: this.$parent.cutoff				
            };	
			var formData = new FormData();
            formData.append("data", JSON.stringify(data));                
            this.axios({
                method: 'post',
                url: this.$config[process.env.VUE_APP_ENV].app.base+'data/php/payroll/pledge.update.php',                
                data: formData
            }).then(response => {                       
                //Remove deleted pledge                               
				if (response.data.error) {
					_this.$root.setAlert(true, response.data.error, 'alert-danger');
                    _this.loading = false;
					return;
				} else {
                    _this.$parent.pledges = _this.$parent.pledges.filter(pl => {return pl.PLEDGE_ID != p.PLEDGE_ID});                    
                    _this.loading = false;
                    _this.$root.setAlert(true, 'Your pledge has been cancelled', 'alert-success');
                    this.modalClose();              
                }                
    		}).catch(error => {
				_this.$root.setAlert(true, error, 'alert-danger');
                _this.loading = false;
				return;
    		}); 
        },
        modalClose: function() {
            this.$parent.modalPledgeDelete.hide();
        }
    },
    mounted: function() {
        this.$parent.modalPledgeDelete = new Modal(document.getElementById('modalPledgeDelete'));   
    }
}    
</script>
<style scoped>
h3 {
    font-size: 1.8rem;
    font-family: "Roboto Slab",serif;       
}
.modal-footer {
    display: block;
}
</style>